import type { NextPage } from "next";
import dynamic from "next/dynamic";
// import Layout from "comp/layout";
// import HomePage from "cont/HomePage";
const Layout = dynamic(() => import("src/components/Layout/layout"));
const HomePage = dynamic(() => import("cont/HomePage"));
import React from "react";
import { finalizedCookie } from "utils/authorizationHelper";

interface HomeProps {
  isValidData: boolean;
  defaultSelectedType: String;
}

const Home = ({ isValidData, defaultSelectedType }: HomeProps) => {
  React.useEffect(() => {
    if (!isValidData) {
      document.cookie = `defaultSelected=hotel;max-age=86400; path=/`;
    }
  }, []);

  return (
    <Layout isHomePage>
      <HomePage defaultSelectedType={defaultSelectedType} />
    </Layout>
  );
};

export default Home;

export async function getServerSideProps(ctx: {
  req: { headers: { cookie: string; host: string } };
}) {
  const cookie =
    ctx.req?.headers &&
    finalizedCookie("defaultSelected", ctx.req?.headers?.cookie);
  let isValidData,
    defaultSelectedType: string = "hotel";

  isValidData = ["hotel", "flight"].includes(cookie);
  if (isValidData) {
    defaultSelectedType = cookie;
  }

  const host = ctx.req.headers.host;
  // const origin = `http://${host}`;
  // const imageUrls = [
  //   origin + "/image/homepage/hotel_bg.webp",
  //   origin + "/image/homepage/flight_bgold.webp",
  // ];
  try {
    // await Promise.all(imageUrls.map((imageUrl) => fetch(imageUrl)));
  } catch (error) {
    console.error("Failed to preload images:", error);
  }
  return {
    props: { defaultSelectedType, isValidData },
  };
}
